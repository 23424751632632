/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  HStack,
  ScaleFade,
  Skeleton,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Tfoot,
  Th,
  Thead,
  Tr,
  VStack,
} from '@chakra-ui/react';
import React from 'react';
import { useContext } from 'react';
import AppColors from '../../../../constants/AppColors';
import { FONT_FAMILY } from '../../../../constants/Theme';
import { BatchContext } from '../../../../providers/BatchProvider';
import { priceConverter } from '../../../../utils/priceConverter';
import InfoTooltip from '../../../../components/InfoTooltip';

const RVUbreakdownTable = React.forwardRef((props, ref) => {
  const batchContext = useContext(BatchContext);
  const { opporturnityBreakdown, isOppBreakdownFetching } = batchContext;
  const renderTableValues = (rvu_opp_data, header = '') => {
    const addDoller = [
      'amount',
      'opportunity_provider_cq',
      'opportunity',
      'provider_cq_total',
      'opportunity_total',
      'total_amount'
    ];
    if (Array.isArray(rvu_opp_data) && addDoller.includes(header)) {
      return rvu_opp_data.map(val => {
        return (
          <Text>
            {val
              ? val >= 0
                ? `${priceConverter(val)}`
                : `-${priceConverter(-val)}`
              : 0}
          </Text>
        );
      });
    } else if (Array.isArray(rvu_opp_data)) {
      return rvu_opp_data.map(val => {
        return <Text>{val ? val : 0}</Text>;
      });
    } else {
      return !rvu_opp_data ? '-' : rvu_opp_data;
    }
  };

  const renderCustomTable = () => {
    return (
      <ScaleFade initialScale={0.5} in={true}>
        <Box className="orc_table" maxHeight={'400px'} overflowY={'scroll'}>
          <Table>
            <Thead>
              <Tr>
                <Th>&nbsp;</Th>
                <Th>Provider-RI</Th>
                <Th>DOWN/UP</Th>
                <Th>Amount</Th>
                <Th>RVU</Th>
                <Th>Provider-RI</Th>
                <Th>Opportunity</Th>
              </Tr>
            </Thead>
            <Tbody>
              {!opporturnityBreakdown?.result?.length ? (
                <Tr>
                  <Td>-</Td>
                  <Td>-</Td>
                  <Td>-</Td>
                  <Td>No Data</Td>
                  <Td>-</Td>
                  <Td>-</Td>
                  <Td>-</Td>
                </Tr>
              ) : (
                opporturnityBreakdown?.result?.map((value, i) => {
                  return (
                    <Tr
                      style={{
                        backgroundColor: i % 2 === 0 ? '#F1F7FF' : 'inherit',
                      }}
                    >
                      <Td>{renderTableValues(value?.srvc_no)}</Td>
                      <Td>{renderTableValues(value?.count_provider_cq)}</Td>
                      {/* <Td>{renderTableValues(value?.under_over)}</Td> */}
                      <Td>
                        <Text>{value?.downcoded}</Text>
                        <Text>{value?.upcoded}</Text>
                      </Td>
                      <Td>{renderTableValues([value?.amount], 'amount')}</Td>
                      <Td>{renderTableValues(value?.rvu)}</Td>
                      {!opporturnityBreakdown?.result?.length ? (
                        <Td>There is no data to display</Td>
                      ) : (
                        <>
                          <Td>
                            {renderTableValues(
                              value?.opportunity_provider_cq,
                              'opportunity_provider_cq'
                            )}
                          </Td>
                          <Td color={'#77C586'}>
                            {renderTableValues(
                              [value?.opportunity],
                              'opportunity'
                            )}
                          </Td>
                        </>
                      )}
                    </Tr>
                  );
                })
              )}
            </Tbody>
            <Tfoot>
              <Tr>
                <Td>Total</Td>
                <Td></Td>
                <Td>
                  <Text fontWeight={'bold'}>
                    {opporturnityBreakdown?.total_downcoded} / {opporturnityBreakdown?.total_upcoded}
                  </Text>
                </Td>
                <Td>
                  <Text fontWeight={'bold'}>
                    {renderTableValues(
                      [opporturnityBreakdown?.total_amount],
                      'total_amount'
                    )}
                  </Text>
                </Td>
                <Td></Td>
                <Td>
                  {/* <Text fontWeight={'bold'}>
                  {renderTableValues(
                    [opporturnityBreakdown?.provider_cq_total],
                    'provider_cq_total'
                  )}
                  </Text> */}
                </Td>
                <Td>
                  <Text fontWeight={'bold'}>
                    {renderTableValues(
                      [opporturnityBreakdown?.opportunity_total],
                      'opportunity_total'
                    )}
                  </Text>
                </Td>
              </Tr>
            </Tfoot>
          </Table>
        </Box>
      </ScaleFade>
    );
  };

  const renderFailedPage = () => {
    return (
      <VStack justifyContent={'center'} h={'100%'} textAlign={'center'}>
        <Text>Something went wrong, Please try again later</Text>
      </VStack>
    );
  };

  const renderLoader = () => {
    return (
      <Stack>
        <Skeleton height="20px" />
        <Skeleton height="20px" />
        <Skeleton height="20px" />
      </Stack>
    );
  };

  const renderMainContent = () => {
    if (isOppBreakdownFetching) {
      return renderLoader();
    } else if (!opporturnityBreakdown) {
      return renderFailedPage();
    } else {
      return renderCustomTable();
    }
  };

  return (
    <Box
      height={'449px'}
      // max-height: 150px;
      id={'outstandingRevenue'}
      ref={ref}
      bg={AppColors.white}
      padding={4}
      display={'flex'}
      flexDirection={'column'}
      bgColor={'#fff'}
      borderRadius={'lg'}
      shadow={'md'}
    >
      {' '}
      {/* header */}
      <HStack mb={2} justifyContent={'space-between'} gap={'50px'}>
        <Text fontFamily={FONT_FAMILY.ptSans} color={AppColors.primary}>
          Outstanding Revenue Opportunity Breakdown
        </Text>
        <InfoTooltip infoContent={
          <>
            <p>
              <b>Outstanding Revenue Opportunity Breakdown</b> section displays each hospital in your system. Click on a hospital to view further details, providing a comprehensive overview of each facility's performance and metrics.
            </p>
          </>} />
      </HStack>
      {/* content */}
      {renderMainContent()}
    </Box>
  );
});

export default RVUbreakdownTable;
